









































import { Component, Prop, Vue } from 'vue-property-decorator'
import Loader from '@bertazzoni/common/src/components/Loader.vue'
import UploadCsv from '@bertazzoni/back/components/UploadCsv.vue'
import UsersService from '@bertazzoni/common/src/services/UsersService'
import { NewUserDto } from '@bertazzoni/common/src/models/user.model'
import { EntityType } from '@bertazzoni/common/src/models/entity.model'
import CheckBox from '@bertazzoni/common/src/components/CheckBox.vue'
import { getUserTypeFromEntityType } from '@bertazzoni/common/src/helpers/userTypeFromEntityType'
import EventBus from '@bertazzoni/common/src/helpers/eventBus'

@Component({
  components: { Loader, CheckBox, UploadCsv }
})
export default class AddUserCsv extends Vue {
  private pageLoaded = false

  private usersCSV = []
  private errorImport = false
  private loading = false
  private usersError = 0
  private usersSuccess = 0

  @Prop({ required: true })
  entityType: EntityType

  async created(): Promise<void> {
    this.pageLoaded = true
  }
  getUser(newUsersCSV: any, headerImport: string[]) {
    if (!newUsersCSV) {
      this.usersCSV = []
      this.usersError = 0
      this.usersSuccess = 0
    } else {
      this.usersCSV = newUsersCSV
    }
  }

  async insertUsers() {
    const users: NewUserDto[] = []
    this.loading = true
    this.usersError = 0
    this.usersSuccess = 0
    let uDto: NewUserDto
    this.usersCSV.forEach((u) => {
      uDto = new NewUserDto()
      uDto.email = u.email
      uDto.firstName = u.firstName
      uDto.lastName = u.lastName
      uDto.language = u.language
      uDto.entityAccess = u.entityAccess
      uDto.entityType = this.entityType
      uDto.type = getUserTypeFromEntityType(this.entityType)
      users.push(uDto)
    })
    const promises = []
    users.forEach((u) => {
      promises.push(
        UsersService.createOne(u)
          .then(() => this.usersSuccess++)
          .catch(() => this.usersError++)
      )
    })
    try {
      await Promise.all(promises)
      EventBus.$emit('close', 'all')
      this.loading = false
    } catch (error) {
      EventBus.$emit('Error', 'CSV format incorrect')
      this.loading = false
    }
  }
}
