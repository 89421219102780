

















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Icons from '@bertazzoni/common/src/components/Icons.vue'
import { FileTypeFormat, SIZE_MAX } from '@bertazzoni/common/src/helpers/utils/fileManager'
import EventBus from '@bertazzoni/common/src/helpers/eventBus'
import { UserError } from '@bertazzoni/common/src/models/user.error'
import csvtojson from 'csvtojson'

@Component({
  components: { Icons }
})
export default class Uploader extends Vue {
  @Prop({ default: true }) reset!: boolean

  private file: File = null
  private format = FileTypeFormat.csv
  private errorFile = ''

  remove(): void {
    this.file = null
    this.$emit('file-uploaded', null, [])
  }
  dragover(event: DragEvent): void {
    event.preventDefault()
    if (!(event.target as Element).classList.contains('bg-gray-300')) {
      ;(event.target as Element).classList.add('bg-gray-300')
    }
  }
  dragleave(event: DragEvent): void {
    ;(event.target as Element).classList.remove('bg-gray-300')
  }
  drop(event: DragEvent): void {
    event.preventDefault()
    this.file = event.dataTransfer.files[0]
    ;(event.target as Element).classList.remove('bg-gray-300')
  }
  checkError(newFile: File): boolean {
    const format = newFile.name.split('.')[newFile.name.split('.').length - 1].toLowerCase()
    if (!this.checkFormat(format)) {
      this.errorFile = 'format'
      return false
    }
    if (newFile.size > SIZE_MAX) {
      // 500 Mo
      this.errorFile = 'size'
      return false
    }
    return true
  }
  checkFormat(uploadFormat: string): boolean {
    const formatList: string[] = this.format.replaceAll('.', '').split(',')
    const formatOk = formatList.findIndex((format: string) => format === uploadFormat) !== -1
    return formatOk
  }
  csvJSON(csv: string) {
    csv = csv.replace('\r', '')
    const lines = csv.split('\n')
    const result = []
    const headers = lines[0].split(';')
    const parseHeader = lines[0].split(';')
    lines.map((line: string, indexLine: number) => {
      if (indexLine > 0) {
        line = line.replace('\r', '')
        const obj = {}
        const currentline = line.split(';')

        headers.map((header: string, indexHeader: number) => {
          obj[header] = currentline[indexHeader]
        })
        result.push(obj)
      }
    })
    result.pop() // remove the last item because undefined values

    this.$emit('file-uploaded', result, parseHeader)
  }

  async loadCSV() {
    if (window.FileReader) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this
      const reader = new FileReader()
      const users: Record<string, any>[] = []

      reader.readAsText(this.file)
      // Handle errors load
      reader.onload = function(event) {
        const csv = event.target.result as string
        csvtojson({
          delimiter: 'auto'
        })
          .fromString(csv)
          .subscribe(
            (json) => {
              users.push(json)
            },
            (err) => {
              console.log('error', err)
              EventBus.$emit(
                'Error',
                new UserError({ message: 'There was an error parsing the csv' })
              )
            },
            () => {
              vm.$emit('file-uploaded', users)
            }
          )
        //vm.csvJSON(csv as string)
      }
      reader.onerror = function(evt) {
        if (evt.target.error.name == 'NotReadableError') {
          EventBus.$emit('Error', new UserError({ message: 'Cannot read file' }))
        }
      }
    } else {
      EventBus.$emit(
        'Error',
        new UserError({ message: 'FileReader is not supported in this browser.' })
      )
    }
  }

  @Watch('file')
  fileChange(newValue: File): void {
    if (newValue) {
      if (!this.checkError(newValue)) {
        this.file = null
        this.$emit('file-uploaded', null, [])
      } else {
        this.errorFile = ''
        this.loadCSV()
      }
    }
  }
  @Watch('reset')
  resetUpload(): void {
    this.remove()
  }
}
