import { EntityType } from "../models/entity.model"
import { UserType } from "../models/user.model"
import store from "../store"


export function getUserTypeFromEntityType(entityType) {
    switch (entityType) {
        case EntityType.country:
            return UserType.countryAdmin
        case EntityType.superDealer:
            return UserType.superDealerRep
        case EntityType.dealer:
            return UserType.seller
        case EntityType.distributor:
            if (store.getters['User/currentUser'].type == UserType.distributorRep)
                return UserType.salesRep
            else
                return UserType.distributorRep
    }
}