












































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import Loader from '@bertazzoni/common/src/components/Loader.vue'
import UsersService from '@bertazzoni/common/src/services/UsersService'
import { NewUserDto, UserType } from '@bertazzoni/common/src/models/user.model'
import { EntityType } from '@bertazzoni/common/src/models/entity.model'
import EntitiesService from '@bertazzoni/common/src/services/EntitiesService'
import { getLanguageList } from '@bertazzoni/common/src/helpers/utils/utils'
import CheckBox from '@bertazzoni/common/src/components/CheckBox.vue'
import { getUserTypeFromEntityType } from '@bertazzoni/common/src/helpers/userTypeFromEntityType'
import { isErrorNotFound } from '@bertazzoni/common/models/user.error'

@Component({
  components: { Loader, CheckBox }
})
export default class AddUser extends Vue {
  private pageLoaded = false
  private languageList: string[] = []
  private distributorUserTypeList: UserType[] = [UserType.distributorRep, UserType.salesRep]
  private entityList: string[] = []
  private userType = this.$store.getters['User/currentUser']
  private user: NewUserDto = new NewUserDto()

  private errorMessage: string = null

  @Prop({ required: true })
  entityType: EntityType

  async created(): Promise<void> {
    if (this.$route.params.entityId) {
      this.user.entityAccess = this.$route.params.entityId
    }
    this.user.entityType = this.entityType as EntityType
    this.languageList = await getLanguageList(true)
    this.getEntityList()
    if (this.entityType != EntityType.distributor) {
      this.user.type = getUserTypeFromEntityType(this.entityType)
    } else if (
      this.entityType == EntityType.distributor &&
      this.userType.type == UserType.distributorRep
    ) {
      this.distributorUserTypeList = [UserType.salesRep]
    }
    this.pageLoaded = true
  }

  async getEntityList(): Promise<void> {
    try {
      this.entityList = await EntitiesService.findEntitiesByType(
        this.$route.params.entityType as EntityType
      )
    } catch (e) {
      if (!isErrorNotFound(e)) {
        throw e
      }
      this.errorMessage = `Add some entities of type: '${this.$route.params.entityType}' to create this kind of user.`
    }
    if (
      this.userType.type == UserType.distributorRep &&
      this.entityType == EntityType.distributor
    ) {
      this.entityList.push(this.userType.linkedEntityId[0])
    }
  }

  goToUserList(): void {
    this.$router.push(`/users`)
  }

  async createUser(): Promise<void> {
    await UsersService.createOne(this.user)
    this.$router.push(`/users`)
  }
}
