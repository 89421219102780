































import { Component, Vue } from 'vue-property-decorator'
import Loader from '@bertazzoni/common/src/components/Loader.vue'
import { EntityType } from '@bertazzoni/common/src/models/entity.model'
import CheckBox from '@bertazzoni/common/src/components/CheckBox.vue'
import AddUser from './AddUser.vue'
import AddUserCsv from './AddUserCsv.vue'

@Component({
  components: { Loader, CheckBox, AddUser, AddUserCsv }
})
export default class AddUserContainer extends Vue {
  entityType: EntityType
  importFromCsv = false

  async created(): Promise<void> {
    this.entityType = this.$route.params.entityType as EntityType
  }

  changeModeForCsv(mode: boolean) {
    this.importFromCsv = mode
  }
}
